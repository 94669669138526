import { Component, OnInit } from '@angular/core';
import {NbDialogRef} from "@nebular/theme";
import {SubscriptionsService} from "../../../services/external/subscriptions.service";
import {SettingsService} from "../../../services/external/settings.service";
import {MagazinesService} from "../../../services/external/magazines.service";
import {AddressesService} from "../../../services/external/addresses.service";

@Component({
  selector: 'ngx-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss']
})
export class SubscriptionModalComponent implements OnInit {

  subscription;
  magazines;
  salutations;
  billingAddress;
  initialAddress;
  create;
  currentUserId;
  customerId;
  editFlag = false;
  subscriptionDate: Date;
  subscriptionNoticeDate: Date;
  subscriptionEndDate: Date;
  magazineSubscription;
  price;
  shippingInland;
  shippingAbroad;
  addressSearch: string;
  locations;

  constructor(
    protected ref: NbDialogRef<SubscriptionModalComponent>,
    private subscriptionService: SubscriptionsService,
    private settingsService: SettingsService,
    private magazineService: MagazinesService,
    private addressService: AddressesService
  ) { }

  ngOnInit(): void {
    console.log(this.subscription);
    console.log(this.currentUserId);
    if (this.create) {
      this.createSubscription();
      this.editFlag = true;
    } else {
      const magazine = this.magazines.filter(magazine => {
        return magazine.id == this.subscription.magazine;
      });
      if(magazine.length > 0) {
        //this.subscription.subscription_no = magazine[0].last_id + 1;
        this.price = magazine[0].price;
        this.shippingInland = magazine[0].shipping_inland;
        this.shippingAbroad = magazine[0].shipping_abroad;
      }
    }
    this.initialAddress = this.subscription.address;
    this.subscriptionDate = this.subscription.date ? this.subscription.date.toDate() : null;
    this.subscriptionNoticeDate = this.subscription.notice_date ? this.subscription.notice_date.toDate() : null;
    this.subscriptionEndDate = this.subscription.subscription_end ? this.subscription.subscription_end.toDate() : null;
  }

  ngOnDestroy() {
    if (this.magazineSubscription) {
      this.magazineSubscription.unsubscribe();
    }
  }

  createSubscription() {
    this.subscription = {
      "member": false,
      "magazine": null,
      "discount_type": "",
      "notice_date": null,
      "customer": this.customerId,
      "updated": null,
      "amount": 0,
      "free_shipping": false,
      "subscription_end": null,
      "currency": "EUR",
      "order_code": "",
      "subscription_no": null,
      "created": null,
      "discount": 0,
      "discount_fixed": 0,
      "created_by": null,
      "shipping": 0,
      "date": null,
      "updated_by": null
    };

    this.subscription.address = this.billingAddress;
    this.subscription.address.same_as_billing = true;
  }


  isActive(subscription_end: any) {
    var now = new Date();
    if (null === subscription_end || subscription_end > now) {
      return true;
    } else {
      return false;
    }
  }

  getMagazineName(magazineId) {
    const magazine = this.magazines.filter(magazine => {
      return magazine.id === magazineId;
    });
    if(magazine.length > 0) {
      return magazine[0].name;
    } else {
      return '';
    }
  }

  getMagazinePreNumber(magazineId) {
    const magazine = this.magazines.filter(magazine => {
      return magazine.id === magazineId;
    });
    if(magazine.length > 0) {
      return magazine[0].pre_number;
    } else {
      return '';
    }
  }

  getSubscriptionNumber(subscriptionNo) {
    const number: string = subscriptionNo + '';
    if(number.length == 1) {
      return '00' + number;
    } else if(number.length == 2) {
      return '0' + number;
    } else {
      return number;
    }
  }

  onSelectMagazine() {
    this.magazineSubscription = this.magazineService.getMagazines().valueChanges().subscribe((magazinesSnapshot) => {
      if(magazinesSnapshot.length > 0) {
        const magazine: any = magazinesSnapshot.filter((magazine: any) => {
          return magazine.id == this.subscription.magazine;
        });
        if(magazine.length > 0) {
          this.subscription.subscription_no = magazine[0].last_id + 1;
          this.price = magazine[0].price;
          this.shippingInland = magazine[0].shipping_inland;
          this.shippingAbroad = magazine[0].shipping_abroad;
        }
      }
    });
  }

  getFullName(address: any) {
    return this.addressService.getFullName(address, this.salutations);
  }

  getFullAddress(address: any) {
    return this.addressService.getFullAddress(address);
  }

  copyAddress(address: any) {
    return this.addressService.getFullNameAddress(address, this.salutations);
  }

  close() {
    this.ref.close();
  }

  edit() {
    this.editFlag = true;
  }

  cancel() {
    this.editFlag = false;
  }

  switchAddress(event) {
    if(this.subscription.address.same_as_billing) {
      this.initialAddress = this.subscription.address;
      this.subscription.address = this.billingAddress;
    } else {
      this.subscription.address = this.initialAddress;
    }
  }

  save() {
    this.subscription.date = this.subscriptionDate ? new Date(this.subscriptionDate) : null;
    this.subscription.notice_date = this.subscriptionNoticeDate ? new Date(this.subscriptionNoticeDate) : null;
    this.subscription.subscription_end = this.subscriptionEndDate ? new Date(this.subscriptionEndDate) : null;

    this.subscription.updated = new Date();
    this.subscription.updated_by = this.currentUserId;
    this.subscriptionService.updateSubscription(this.subscription.id, this.subscription).then((result) => {
      this.editFlag = false;
      this.close();
    }).catch((err) => {
      console.log(err);
    });
  }

  addSubscription() {
    this.subscription.date = this.subscriptionDate ? new Date(this.subscriptionDate) : null;
    this.subscription.notice_date = this.subscriptionNoticeDate ? new Date(this.subscriptionNoticeDate) : null;
    this.subscription.subscription_end = this.subscriptionEndDate ? new Date(this.subscriptionEndDate) : null;

    this.subscription.created = new Date();
    this.subscription.updated = new Date();
    this.subscription.updated_by = this.currentUserId;
    this.subscription.created_by = this.currentUserId;
    this.subscriptionService.createSubscription(this.subscription).then((result) => {
      this.editFlag = false;
      this.close();
    }).catch((err) => {
      console.log(err);
    });
  }

  onFindAddress(event) {
    if (this.addressSearch.length >= 5) {
      /*this.addressService.findAddress(searchFor).subscribe((result) => {
        console.log(result);
      });*/
      this.addressService.getAddress(this.addressSearch).then(result => {
        this.locations = <Array<any>>result;
        console.log(this.locations);
        //this.filteredOptions$ = this.getFilteredOptions(event);
        //console.log(result);
      }, error => {
        this.locations = <Array<any>>[];
        console.error(error);
      });
    }
  }

  onSelectAddress($event) {
    if ($event && $event.address) {
      console.log($event);
      console.log($event.address);
      /*
      City: "Nürtingen"
      Country: "DEU"
      County: "Esslingen"
      District: "Neckarhausen"
      HouseNumber: "7/1"
      Label: "Im Maueracker 7/1, 72622 Nürtingen, Deutschland"
      PostalCode: "72622"
      State: "Baden-Württemberg"
      Street: "Im Maueracker"
      */
      const address = $event.address;

      const street = address.street || '';
      const houseNumber = address.houseNumber || '';
      let streetSpace = '';
      if (houseNumber) {
        streetSpace = ' ';
      }

      this.subscription.address.city = address.city;
      this.subscription.address.street = street + streetSpace + houseNumber;
      this.subscription.address.postal_code = address.postalCode;
      this.subscription.address.country = this.addressService.matchCountry(address.countryCode);
      this.subscription.address.label = address.label;
      this.addressSearch = "";
    }
  }

}
